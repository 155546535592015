<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item mode="md" lines="full" v-for="languageInstance of languages" :key="languageInstance.id" @click="editLanguage(languageInstance.id)">
                <ion-label>
                    <h2 :class="{disabled: !languageInstance.enabled}">{{ languageInstance.language }}, {{ languageInstance.code.toUpperCase() }} <span v-if="!languageInstance.enabled"> - {{ language.disabled }}</span></h2>
                </ion-label>
                <DirectionIndicator class="edit-forward" :is-ltr-forward="true"/>
            </ion-item>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonItem
} from "@ionic/vue";
import {execute} from "@/mixins/LoadingMixin";
import FixedFooter from "@/components/FixedFooter.vue";
import {useStore} from "vuex";
import {getLanguages} from "@/services/LanguageService";
import { Language } from "@/models/LanguageModel";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import router from "@/router";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: "Languages",
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonLabel,
        IonItem,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.languageInstance);
        await execute(async () => {
            this.languages = await getLanguages(false);
        }, this.language.errors.gettingJourneySets);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const languages = ref([] as Language[]);
        
        const editLanguage = (id: string) => {
            router.push({ name: 'editLanguage', params: { id: id }});
        }
        
        return {
            store,
            language,
            languages,
            editLanguage,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, () => router.push({ name: 'addLanguage' }), language.value.addLanguageInstance)
                .create()
            )
        }
    }
});
</script>

<style scoped>
ion-item ion-label.sc-ion-label-md-h h2.disabled {
    color: var(--color-step-600);
}
</style>