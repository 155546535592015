import {GET, POST, PUT} from './HTTP';
import {Language} from "@/models/LanguageModel";

export function getLanguages(enabledOnly: boolean): Promise<Language[]>{
    return GET('/languages?enabledOnly=' + enabledOnly);
}

export function getLanguage(id: string, enabledOnly: boolean): Promise<Language>{
    return GET(`/languages/${id}?enabledOnly=${enabledOnly}`);
}

export function updateLanguage(language: Language): Promise<any>{
    return PUT(`/languages/${language.id}`, language);
}

export function createLanguage(language: Language): Promise<any>{
    return POST('/languages', language);
}
 