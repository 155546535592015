
import {computed, defineComponent, ref} from "vue";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonItem
} from "@ionic/vue";
import {execute} from "@/mixins/LoadingMixin";
import FixedFooter from "@/components/FixedFooter.vue";
import {useStore} from "vuex";
import {getLanguages} from "@/services/LanguageService";
import { Language } from "@/models/LanguageModel";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import router from "@/router";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: "Languages",
    components: {
        DirectionIndicator,
        IonContent,
        IonPage,
        IonLabel,
        IonItem,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.languageInstance);
        await execute(async () => {
            this.languages = await getLanguages(false);
        }, this.language.errors.gettingJourneySets);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const languages = ref([] as Language[]);
        
        const editLanguage = (id: string) => {
            router.push({ name: 'editLanguage', params: { id: id }});
        }
        
        return {
            store,
            language,
            languages,
            editLanguage,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, () => router.push({ name: 'addLanguage' }), language.value.addLanguageInstance)
                .create()
            )
        }
    }
});
